@import url('https://fonts.googleapis.com/css2?family=Pangolin&family=Unbounded:wght@200..900&display=swap');

@font-face {
  font-family: "Milk Kids";
  src: url("./components/assets/font/Milk\ Kids.ttf");
}

.font-milk {
  font-family: "Milk Kids";
}

.font-Unbounded {
  font-family: "Unbounded", sans-serif;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Pangolin";
  background-image: url("./components/assets/png/body-bg.png");
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
}

.text-shadow {
  text-shadow: 2.304px 2.304px 0px #000;
}

.about-bg {
  background-image: url("./components/assets/png/about-bg.png");
  background-size: 100% 100%;
  background-position: center;
}

.how-to-buy-bg {
  border-radius: 21.922px;
  border: 4px solid rgba(0, 0, 0, 0.10);
  background: #FFF7F7;
  backdrop-filter: blur(10px);
}

@media (max-width:1023.98px) {
  .about-bg {
    background-size: 200% 100%;
  }

}

@media (max-width:500px) {
  .about-bg {
    background-size: 300% 100%;
  }

}

.animate-spin {
  animation: spin 5s linear infinite;
}